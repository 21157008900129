<template>
  <main id="hero" class="fill-height   bg-blue-600/30 backdrop-brightness-75 full-screen main">
    <v-row>
      <v-row align="center" justify="center">
        <v-col cols="4" md="6" xl="4"> </v-col>
        <v-col cols="8" md="6" xl="8" class="hidden-sm-and-down mt-10"> </v-col>
      </v-row>
    </v-row>

    <v-row>
      <div class="d-flex mt-5 ml-10">
        <img
          src="@/assets/images/emblem.png"
          alt="Logo"
          width="60"
          height="50"
        />
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex mt-5 mr-10">
        <img
          src="@/assets/images/eDodoso_logo.png"
          alt="Logo"
          width="200"
          height="50"
        />
      </div>
    </v-row>
    <div class="mb-15">
      <center>
        <h1 class="font-light text-white hover:text-white">
          <p class="text-2xl font-normal font-extrabold top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 tracking-tight md:tracking-wide">
           eDodoso Platform for Data Collection, Analysis and Reports Generation
          </p>
        </h1>
      </center>
    </div>

    <div class="flex flex-row justify-center">
      <div class="border border-2 w-full mx-8 md:w-1/2 bg-white rounded-md">
        <div class="flex flex-row">
          <input
            type="text"
            v-model="surveyNumber"
            class="border-0 ml-2 p-2 focus:outline-none grow"
            placeholder="Enter survey number"
          />
          <!-- #005464 -->
          <v-btn color="#005464" class="grow-0 m-2" @click="goToSurvey"
            ><v-icon color="white" class="text-transform:none"
              >mdi-open-in-new</v-icon
            ><span class="text-white text-transform:none">Open</span></v-btn
          >
        </div>
      </div>
    </div>

    <div class="mb-24">
      <!-- First 3 Cards -->

      <div class="flex flex-col md:flex-row justify-center md:my-8">
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'dodoso_lite'"
            :systemName="'eDodoso LITE'"
            :system-url="'https://edodosov2.all.co.tz/landing/'"
          ></system-card>
        </div>

        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'dodoso_pro'"
            :system-url="'https://edodoso.gov.go.tz/'"
          ></system-card>
        </div>

        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'public_survey'"
            :systemName="'View Public Survey'"
            link
            :to="{ name: `Public Survey` }"
          ></system-card>
        </div>
      </div>

      <!-- second 2 Cards -->
      <div class="flex flex-col md:flex-row justify-center mt-8">
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'online_quiz'"
            :systemName="'Take Online Quiz'"
            :system-url="'https://quiz.edodoso.gov.go.tz/'"
          ></system-card>
        </div>
        <div class="w-full my-2 md:my-0 md:w-1/5">
          <system-card
            :logo-type="'eUkaguzi'"
            :systemName="'eUkaguzi'"
            :system-url="'https://ukaguzi.edodoso.gov.go.tz/'"
          ></system-card>
        </div>
      </div>
    </div>

    <!-- <div class="svg-border-waves"></div> -->
    <Footer />
  </main>
</template>
<script>
import SystemCard from "./SystemCard.vue";
import Footer from "./FooterComponent.vue";

import Swal from "sweetalert2";
export default {
  components: { SystemCard, Footer },
  data() {
    return {
      surveyNumber: "",
    };
  },
  methods: {
    async goToSurvey() {
      if (this.surveyNumber != "") {
        if (this.surveyNumber.length === 6 && parseInt(this.surveyNumber)) {
          window.open(
            "https://edodoso.gov.go.tz/index.php/" +
              this.surveyNumber +
              "?lang=en"
          );
        } else if (this.surveyNumber.length === 6) {
          // Handle public survey for dodoso lite
          await this.$store
            .dispatch("fetchSurveyByNumber", this.surveyNumber)
            .then(() => {
              console.log("The survey number is", this.surveyNumber);
              window.open(
                "https://edodosov2.all.co.tz/" +
                  "response/" +
                  this.$store.getters.getPrivateSurveyId
              );
            });
        } else {
          Swal.fire({
            icon: "error",
            text: "Please provide a valid survey number!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          text: "Please provide a survey number!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },

    //     async fetchSurveyByNumber() {
    //   if (this.surveyNumber != "") {
    //     await this.$store.dispatch("fetchSurveyByNumber", this.surveyNumber);
    //     if (this.privateSurveyId) {
    //       this.$router.push("/response/" + this.privateSurveyId);
    //     } else {
    //       this.$router.push("/");
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss">
.circle {
  stroke: white;
  stroke-dasharray: 650;
  stroke-dashoffset: 650;
  -webkit-transition: all 0.5s ease-in-out;
  opacity: 0.3;
}

.playBut {
  /*  border: 1px solid red;*/
  display: inline-block;
  -webkit-transition: all 0.5s ease;

  .triangle {
    -webkit-transition: all 0.7s ease-in-out;
    stroke-dasharray: 240;
    stroke-dashoffset: 480;
    stroke: white;
    transform: translateY(0);
  }

  &:hover {
    .triangle {
      stroke-dashoffset: 0;
      opacity: 1;
      stroke: white;
      animation: nudge 0.7s ease-in-out;

      @keyframes nudge {
        0% {
          transform: translateX(0);
        }
        30% {
          transform: translateX(-5px);
        }
        50% {
          transform: translateX(5px);
        }
        70% {
          transform: translateX(-2px);
        }
        100% {
          transform: translateX(0);
        }
      }
    }

    .circle {
      stroke-dashoffset: 0;
      opacity: 1;
    }
  }
}

</style>

<style>
.btn-play {
  transition: 0.2s;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
</style>



<style> 
main {
  position: relative;
  animation: mymove 75s infinite;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  /* ba */
}

@keyframes mymove {
  0% {
     background:linear-gradient(0deg, rgba(14, 54, 107, 0.626), rgba(14, 54, 107, 0.626)), url('../../assets/tz.jpeg');
     background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  }
 25% {
     background:linear-gradient(0deg, rgba(14, 54, 107, 0.626), rgba(14, 54, 107, 0.626)), url('../../assets/images/giraffe.jpg');
     background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  }
  50% {
   background:linear-gradient(0deg, rgba(14, 54, 107, 0.626), rgba(14, 54, 107, 0.626)), url('../../assets/mbudya.jpg');
   background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  }
  75% {
     background:linear-gradient(0deg, rgba(14, 54, 107, 0.626), rgba(14, 54, 107, 0.626)), url('../../assets/images/tanzanite_brigde_one_01.jpg');
     background-size: cover;
     background-repeat: no-repeat;
     background-position:center;
  }
  100% {
    background:linear-gradient(0deg, rgba(14, 54, 107, 0.626), rgba(14, 54, 107, 0.626)),  url('../../assets/dar_bridge.jpg');
     background-size: cover;
     background-repeat: no-repeat;
     background-position:center;
  }
}
</style>
