<template>
  <div
    class="
      flex flex-col
      mx-2
      p-8
      bg-white
      rounded-lg
      shadow-sm
      hover:cursor-pointer hover:shadow-lg
      items-center
      h-48 max-h-full
      justify-space-between
      max-w-xs
    "
    v-on:click="onClickHandler"
  >
    <v-tooltip bottom color="#005464">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="logoType == 'dodoso_pro'"
          class="border-red-500 border-capacity-100 mt-5"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: #005464" class="text-xl font-semibold"
            >eDodoso PRO</span
          >
          <img
            src="../../assets/images/monitor.png"
            class="flex mx-auto"
            alt="public survey"
            width="50"
            height="50"
          />
        </div>
      </template>
      <span>Advanced Data Collection and Analysis Tool</span>
    </v-tooltip>

    <v-tooltip bottom color="#005464">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="logoType == 'dodoso_lite'"
          class="mt-5"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: #005464" class="text-xl font-semibold"
            >eDodoso LITE</span
          >
          <img
            src="../../assets/images/web-analytics.png"
            class="flex mx-auto"
            alt="public survey"
            width="50"
            height="50"
          />
        </div>
      </template>
      <span>Simple and Easy to Use</span>
    </v-tooltip>

    <v-tooltip bottom color="#005464">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="logoType == 'eUkaguzi'"
          class="mt-5"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: #005464" class="text-xl font-semibold"
            >eUkaguzi</span
          >
          <img
            src="../../assets/images/audit.png"
             class="flex mx-auto"
            alt="public survey"
            width="50"
            height="50"
          />
        </div>
      </template>
      <span>Audit,QS,Risk and Security Assement Tool</span>
    </v-tooltip>

    <v-tooltip bottom color="#005464">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="logoType == 'public_survey'"
          class="mt-5"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: #005464" class="text-xl font-semibold"
            >View Public Survey</span
          >
          <img
            src="../../assets/images/analysis.png"
            class="flex mx-auto"
            alt="public survey"
            width="50"
            height="50"
          />
        </div>
      </template>
      <span>Please Provide Opinion By Filling Survey</span>
    </v-tooltip>

    <v-tooltip bottom color="#005464">
      <template v-slot:activator="{ on, attrs }">
        <div
          v-if="logoType == 'online_quiz'"
          class="mt-5"
          v-bind="attrs"
          v-on="on"
        >
          <span style="color: #005464" class="text-xl font-semibold"
            >Take Online Quiz</span
          >
            <img
              src="../../assets/images/examination.png"
              class="flex mx-auto"
              alt="public survey"
              width="50"
              height="50"
            />
        </div>
      </template>
      <span>Answer and Create Self Assement Quiz</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "SystemCard",
  props: {
    logoType: {
      type: String,
    },
    systemName: {
      type: String,
    },
    systemUrl: {
      type: String,
    },
  },
  data() {
    return {
      routeCards: ["dodoso_pro", "dodoso_lite", "eUkaguzi", "online_quiz"],
    };
  },
  methods: {
    onClickHandler() {
      if (this.routeCards.find((routeCard) => routeCard == this.logoType)) {
        window.open(this.systemUrl, "__blank");
      } else {
        // Handle public surveys
        // this.$router.push(.....)
      }
    },
  },
};
</script>


<style scoped>
.colorName {
  color: "#34b8e0";
}
.improveCard {
  border-style: solid 1px;
  border-color: brown;
}

.ImageColor {
  color: #005464;
  justify-content: center;
  align-items: center;
}
</style>