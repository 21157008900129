var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    flex flex-col\n    mx-2\n    p-8\n    bg-white\n    rounded-lg\n    shadow-sm\n    hover:cursor-pointer hover:shadow-lg\n    items-center\n    h-48 max-h-full\n    justify-space-between\n    max-w-xs\n  ",on:{"click":_vm.onClickHandler}},[_c('v-tooltip',{attrs:{"bottom":"","color":"#005464"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.logoType == 'dodoso_pro')?_c('div',_vm._g(_vm._b({staticClass:"border-red-500 border-capacity-100 mt-5"},'div',attrs,false),on),[_c('span',{staticClass:"text-xl font-semibold",staticStyle:{"color":"#005464"}},[_vm._v("eDodoso PRO")]),_c('img',{staticClass:"flex mx-auto",attrs:{"src":require("../../assets/images/monitor.png"),"alt":"public survey","width":"50","height":"50"}})]):_vm._e()]}}])},[_c('span',[_vm._v("Advanced Data Collection and Analysis Tool")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#005464"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.logoType == 'dodoso_lite')?_c('div',_vm._g(_vm._b({staticClass:"mt-5"},'div',attrs,false),on),[_c('span',{staticClass:"text-xl font-semibold",staticStyle:{"color":"#005464"}},[_vm._v("eDodoso LITE")]),_c('img',{staticClass:"flex mx-auto",attrs:{"src":require("../../assets/images/web-analytics.png"),"alt":"public survey","width":"50","height":"50"}})]):_vm._e()]}}])},[_c('span',[_vm._v("Simple and Easy to Use")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#005464"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.logoType == 'eUkaguzi')?_c('div',_vm._g(_vm._b({staticClass:"mt-5"},'div',attrs,false),on),[_c('span',{staticClass:"text-xl font-semibold",staticStyle:{"color":"#005464"}},[_vm._v("eUkaguzi")]),_c('img',{staticClass:"flex mx-auto",attrs:{"src":require("../../assets/images/audit.png"),"alt":"public survey","width":"50","height":"50"}})]):_vm._e()]}}])},[_c('span',[_vm._v("Audit,QS,Risk and Security Assement Tool")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#005464"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.logoType == 'public_survey')?_c('div',_vm._g(_vm._b({staticClass:"mt-5"},'div',attrs,false),on),[_c('span',{staticClass:"text-xl font-semibold",staticStyle:{"color":"#005464"}},[_vm._v("View Public Survey")]),_c('img',{staticClass:"flex mx-auto",attrs:{"src":require("../../assets/images/analysis.png"),"alt":"public survey","width":"50","height":"50"}})]):_vm._e()]}}])},[_c('span',[_vm._v("Please Provide Opinion By Filling Survey")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"#005464"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.logoType == 'online_quiz')?_c('div',_vm._g(_vm._b({staticClass:"mt-5"},'div',attrs,false),on),[_c('span',{staticClass:"text-xl font-semibold",staticStyle:{"color":"#005464"}},[_vm._v("Take Online Quiz")]),_c('img',{staticClass:"flex mx-auto",attrs:{"src":require("../../assets/images/examination.png"),"alt":"public survey","width":"50","height":"50"}})]):_vm._e()]}}])},[_c('span',[_vm._v("Answer and Create Self Assement Quiz")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }